<template>
  <div>
    <div class="container">

      <div style="margin-top: 20px;">
         <h3 class="title is-3">Abertura de chamados</h3>
      </div>
      
      <div class="form"
        v-if="notifi.show == false">

        <section style="margin-top: 20px;">

          <b-field label="Nome do Solicitante (obrigatório)">
            <b-input 
              v-model="form.solicitante"
              placeholder="Nome">
            </b-input>
          </b-field>

          <b-field label="Setor/Local">
            <b-input 
              v-model="form.setor"
              placeholder="Setor/Local">
            </b-input>
          </b-field>

          <b-field label="Patrimônio do equipamento">
            <b-input 
              placeholder="Patrimônio do equipamento"
              v-model="form.patrimonio">
            </b-input>
          </b-field>

          <b-field label="Telefone de Contato">
            <b-input 
              placeholder="Telefone de Contato"
              v-model="form.telefone">
            </b-input>
          </b-field>

          <b-field label="Nós infome mais sobre sua solicitação">
              <b-input 
                maxlength="500" 
                type="textarea"
                placeholder="Deixe aqui um breve relato do problema"
                v-model="form.descricao">
              </b-input>
          </b-field>

        </section>
        <!-- 6LenriEaAAAAACQl8_IPPaoZ9DaQJs4XXv1ZYuRJ -->

        <section>
          (obrigatório)
          <vue-recaptcha  
            sitekey="6LenriEaAAAAAP80J8o6-7FjMRZSDbI4BnBbnEH4"
            @verify="verifyRecap"
            @expired="expiredRecap">
          </vue-recaptcha>
          <br>
        </section>

        <section>
          <b-button
            type="is-link"
            :loading="loading"
            @click="clickBtnenviar">
            Enviar
          </b-button>
        </section>

        <br>

      </div>
      
      <div v-if="notifi.show"  style="margin-top: 50px;">
        <section>
          <b-notification 
            :type="notifi.type" 
            aria-close-label="Close notification"
            v-if="notifi.show"
            v-model="notifi.show">
              {{notifi.msg}}
          </b-notification>
          <b-button 
            type="is-link"
            v-if="showBtnVoltar"
            @click="notifi.show = !notifi.show">
            Voltar
          </b-button>
        </section>
      </div>

    </div>
  </div>
</template>

<script>
  import Request from '@/utils/Request.js';
  import VueRecaptcha from 'vue-recaptcha';

  export default {

    components: { 
      VueRecaptcha
    },

    data() {
      return {
        form: {},
        notifi: {
          type: '',
          show: false,
          msg: 'Menssagem'
        },
        showBtnVoltar: true,
        loading: false,
        session: '',
        isValidRecap: false
      }
    },

    mounted(){
      this.sendInitSession()

      // data para teste 
      this.form = {
        solicitante: 'teste',
        setor: 'teste',
        patrimonio: 'teste',
        // telefone: 'teste',
        descricao: `Ajuda aqui pfv\nmeu pc nao liga `,
      }
    },

    methods: {
      clickBtnenviar(){
        this.loading = true;
        this.showBtnVoltar = false;
        let dataForm = Object.assign({}, this.form);

        // console.log(dataForm);

        if(dataForm.solicitante == undefined && dataForm.recap == undefined){
          this.setErrorNotifi();
        }
        else if(!this.isValidRecap){
          this.setErrorNotifi();
        }
        else if(dataForm.solicitante == ""){
          this.setErrorNotifi();
        }
        else{
          let dataToSend = {
            input: {
              name: 'Novo chamado aberto pelo formulário',
              priority: '1',
              entities_id: '1',
              content: this.mountTicketDescription()
            }
          };
          console.log(dataToSend);
          this.sendPost(dataToSend);
        }
      },
      mountTicketDescription(){
        return `Solicitante: ${this.getFormIfDefined('solicitante')}\nSetor: ${this.getFormIfDefined('setor')}\nPatrimônio: ${this.getFormIfDefined('patrimonio')}\nTelefone: ${this.getFormIfDefined('telefone')}\nDescrição: ${this.getFormIfDefined('descricao')}`
      },
      getFormIfDefined(position){
        if(this.form[position] == undefined){
          return ''
        }
        return this.form[position];
      },
      sendPost(data){
        const success = (response) => {
          // console.log(response);
          this.notifi = {
            type: 'is-success',
            show: true,
            msg: 'Seu chamado foi encaminhado com sucesso. Numero #' + response.data.id + ". Por favor, aguarde. Um técnico ira entra em contato o mais breve possível."
          }
          this.showBtnVoltar = true;
          this.loading = false
        }
        const error = (error) => {
          console.log(error);
          this.setErrorNotifi();
          this.showBtnVoltar = true;
          this.loading = false
        }
        const url = Request.mountUrl('/Ticket');
        Request.postGLPI(url, data, {
            headers: {
              'Content-Type': 'application/json',
              'App-Token': `${process.env.VUE_APP_GLPI_APP_TOKEN}`,
              'Session-Token': `${this.session}`,
            }
          })
          .then(success)
          .catch(error);
      },
      setErrorNotifi(){
        this.notifi = {
          type: 'is-danger',
          show: true,
          msg: 'Erro ao abrir chamado. Verifique se os campos estão preenchidos corretamente. Inclusive o campo "Eu não sou um robô"'
        }
        this.loading = false;
          this.showBtnVoltar = true;
      },
      verifyRecap(response){
        // console.log(response);
        // this.form.recap = response
        this.isValidRecap = true;
      },
      isSession(){
        return this.session != '';
      },
      clearSession(){
        this.session = ''
      },
      sendInitSession(){
        const success = (response) => {
          // console.log(response);
          this.session = response.data.session_token
        }
        const error = (error) => {
          console.log(error);
          this.clearSession()
        }
        const url = Request.mountUrl('/initSession/');
        Request.getGLPI(url, {
            headers: {
              'Authorization': `user_token ${process.env.VUE_APP_GLPI_USER_TOKEN}`,
              'Content-Type': 'application/json',
              'App-Token': `${process.env.VUE_APP_GLPI_APP_TOKEN}`,
            }
          })
          .then(success)
          .catch(error);
      },
      expiredRecap(){
        this.isValidRecap = false;
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>